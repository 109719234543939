<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12.mb-2.title-box
      b-button(variant="secondary" to="/broadcast") 回上一層
    .col-lg-12.mt-2
      b-card.mb-1(no-body)
        b-card-header(header-tag="header", role="tab")
          .header-row
            .header-title 推播內容設定

        b-card-body
          .row
            .col-10.row
              .col-6
                .form-group.mb-3
                  label 通知標題 (15個中文字內)
                  br
                  input.form-control(
                    type="text",
                    v-model="currentBroadcastTitle"
                    placeholder="請輸入通知標題"
                  )

              .col-12
                .form-group.mb-3
                  label 通知文案 (30個中文字)
                  br
                  textarea.form-control(
                    v-model="currentBroadcastBody",
                    rows="3",
                    type="text",
                    placeholder="請輸入通知文案"
                  )
              .col-6
                .form-group.mb-3
                  label 推播跳轉位置
                  br
                  .title-box.mb-2
                    b-button-group.tab-group.pl-0.pt-0
                      b-button.mr-2(:variant="BroadcastTargetType !== 1 ? 'outline-primary' : 'primary'" @click="changeJumpTab(1)") 站內目標
                      b-button.mr-2(:variant="BroadcastTargetType !== 2 ? 'outline-primary' : 'primary'" @click="changeJumpTab(2)") 指定連結
                  .title-box.mb-2(v-if="BroadcastTargetType === 1")
                    b-button-group.tab-group.pl-0.pt-0
                      b-button.mr-2(:variant="BroadcastTargetModel !== 'News' ? 'outline-primary' : 'primary'" @click="changeJumpType('News')") 新聞項目
                      //- b-button.mr-2(:variant="BroadcastTargetModel !== 'Video' ? 'outline-primary' : 'primary'" @click="changeJumpType('Video')") 影音項目
                      //- b-button.mr-2(:variant="BroadcastTargetModel !== 'Poll' ? 'outline-primary' : 'primary'" @click="changeJumpType('Poll')") 投票項目
                      //- b-button.mr-2(:variant="BroadcastTargetModel !== 'Mission' ? 'outline-primary' : 'primary'" @click="changeJumpType('Mission')") 任務項目
                  input.form-control(
                    v-if="BroadcastTargetType === 2"
                    v-model="currentBroadcastUri"
                    type="text",
                    placeholder="請輸入網址連結"
                  )
                  multiselect(
                    v-if="BroadcastTargetType === 1 && BroadcastTargetModel === 'News'"
                    key="searchTargetByNews"
                    v-model="currentTargetItem",
                    :options="NewsTargetOptions",
                    placeholder="請輸入稿件編號或標題",
                    label="name",
                    track-by="id",
                    select-label=""
                    :multiple="false"
                    @search-change="searchNewsList"                        
                  )

      b-card.mb-1(no-body)
        b-card-header(header-tag="header", role="tab")
          .header-row
            .header-title 篩選用戶
        b-card-body
          .row

            .col-10.row 
              .col-6
                .form-group.mb-2
                  label 發送對象
                  br
                  .title-box
                    b-button-group.tab-group.pl-0.pt-0
                      b-button.mr-2(:variant="NeedSearchDeviceStatus !== 1 ? 'outline-primary' : 'primary'" @click="changeFilterTab(1)") 全部裝置
                      b-button.mr-2(:variant="NeedSearchDeviceStatus !== 2 ? 'outline-primary' : 'primary'" @click="changeFilterTab(2)") 篩選裝置
            .col-9.row(v-if="NeedSearchDeviceStatus === 2")
              //- .col-7
                .form-group.mb-2
                  label 搜尋用戶姓名
                  br
                  multiselect(
                    v-model="currentSearchName",
                    :options="MemberOptions",
                    select-label="",
                    :multiple="false"
                    placeholder="請輸入用戶名",
                    label="name",
                    track-by="id",
                    @search-change="searchMemberList"
                  )
              .col-8
                .form-group.mb-2
                  label 裝置所屬平台
                  br
                  .title-box
                    b-button-group.tab-group.pl-0.pt-0
                      b-button.mr-2(:variant="DevicePlatform !== 0 ? 'outline-primary' : 'primary'" @click="changeDevicePlatformTab(0)") 全部
                      b-button.mr-2(:variant="DevicePlatform !== 1 ? 'outline-primary' : 'primary'" @click="changeDevicePlatformTab(1)") Web
                      b-button.mr-2(:variant="DevicePlatform !== 2 ? 'outline-primary' : 'primary'" @click="changeDevicePlatformTab(2)") APP
              .col-8
                .form-group.mb-2
                  label 裝置登入狀態
                  br
                  .title-box
                    b-button-group.tab-group.pl-0.pt-0
                      b-button.mr-2(:variant="DeviceLoginStatus !== 0 ? 'outline-primary' : 'primary'" @click="changeLoginTab(0)") 全部
                      b-button.mr-2(:variant="DeviceLoginStatus !== 1 ? 'outline-primary' : 'primary'" @click="changeLoginTab(1)") 未登入
                      b-button.mr-2(:variant="DeviceLoginStatus !== 2 ? 'outline-primary' : 'primary'" @click="changeLoginTab(2)") 已登入
              .col-10
                .form-group.mb-2
                  label 裝置最近一次進站時間
                  br
                  .title-box
                    b-button-group.tab-group.pl-0.pt-0
                      b-button.mr-2(:variant="DeviceLastUseAt !== 0 ? 'outline-primary' : 'primary'" @click="changeLastTimeTab(0)") 全部
                      b-button.mr-2(:variant="DeviceLastUseAt !== 1 ? 'outline-primary' : 'primary'" @click="changeLastTimeTab(1)") 1天內
                      b-button.mr-2(:variant="DeviceLastUseAt !== 2 ? 'outline-primary' : 'primary'" @click="changeLastTimeTab(2)") 3天內
                      b-button.mr-2(:variant="DeviceLastUseAt !== 3 ? 'outline-primary' : 'primary'" @click="changeLastTimeTab(3)") 5天內
                      b-button.mr-2(:variant="DeviceLastUseAt !== 4 ? 'outline-primary' : 'primary'" @click="changeLastTimeTab(4)") 7天內
                      b-button.mr-2(:variant="DeviceLastUseAt !== 5 ? 'outline-primary' : 'primary'" @click="changeLastTimeTab(5)") 30天內
                      b-button.mr-2(:variant="DeviceLastUseAt !== 6 ? 'outline-primary' : 'primary'" @click="changeLastTimeTab(6)") 超過30天
              .col-8
                .form-group.mb-2
                  label 最近一次綁定用戶的裝置
                  br
                  .title-box
                    b-button-group.tab-group.pl-0.pt-0
                      b-button.mr-2(:variant="DeviceLastLoginAt !== 0 ? 'outline-primary' : 'primary'" @click="changeNewUserTab(0)") 全部
                      b-button.mr-2(:variant="DeviceLastLoginAt !== 1 ? 'outline-primary' : 'primary'" @click="changeNewUserTab(1)") 1天內
                      b-button.mr-2(:variant="DeviceLastLoginAt !== 2 ? 'outline-primary' : 'primary'" @click="changeNewUserTab(2)") 3天內
                      b-button.mr-2(:variant="DeviceLastLoginAt !== 3 ? 'outline-primary' : 'primary'" @click="changeNewUserTab(3)") 5天內

      b-card.mb-1(no-body)
        b-card-header(header-tag="header", role="tab")
          .header-row
            .header-title 推送時間設定

        b-card-body
          .row
            .col-10.row
              .col-6
                .form-group.mb-2
                  label 推送時間
                  br
                  .title-box
                    b-button-group.tab-group.pl-0.pt-0
                      b-button.mr-2(:variant="ScheduleType !== 1 ? 'outline-primary' : 'primary'" @click="changeDateTab(1)") 立即
                      b-button.mr-2(:variant="ScheduleType !== 2 ? 'outline-primary' : 'primary'" @click="changeDateTab(2)") 排程

                .form-group.mb-3(v-if="ScheduleType === 2")
                  label 排程日期
                  br
                  .row
                    .col-6
                      date-picker(
                        v-model="currentScheduleDate",
                        format="YYYY-MM-DD"
                        value-type="format",
                        append-to-body,
                        lang="zh",
                        placeholder="請選擇推送日期"
                      )
                    .col-6
                      date-picker(
                        v-model="currentScheduleTime",
                        format="HH:mm",
                        value-type="format",
                        type="time",
                        placeholder="時 : 分"
                      )

      .row.text-center.mt-3
        .col
          b-button.width-lg.mr-1(variant="primary",v-if="currentBroadcastId === 'create'",@click="createBroadcast") 新增
          b-button.width-lg.mr-1(variant="primary",v-else-if="broadcast.status <= 1",@click="putBroadcast") 更新
          b-button.width-lg.mr-1(variant="danger",v-if="currentBroadcastId !== 'create' && broadcast.status <= 1",@click="cancelBroadcast") 取消
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import Select2 from 'v-select2-component';
import SelectImage from "@/components/select-image";
import draggable from 'vuedraggable'

/**
 * Starter component
 */
export default {
  data () {
    return {
      title: "推播編輯",
      items: [
        {
          text: "推播列表",
          href: "/broadcast",
        },
        {
          text: "推播編輯",
          active: true,
        }
      ],
      broadcast: {
        status: 0
      },
      currentBroadcastTitle: "中天新聞網",
      currentBroadcastBody: "",
      currentBroadcastUri: "",
      currentBroadcastTargetId: "",
      currentBroadcastId: false,
      currentTargetItem: null,
      currentSearchName: '',
      NewsTargetOptions: [],
      MemberOptions: [],
      // 跳轉類型
      BroadcastTargetType: 1,
      BroadcastTargetModel: 'News',
      // 是否要篩選
      NeedSearchDeviceStatus: 2,
      // 裝置平台
      DevicePlatform: 2,
      // 推送時間
      ScheduleType: 2,
      currentScheduleDate: null,
      currentScheduleTime: null,
      // 裝置登入狀態
      DeviceLoginStatus: 0,
      // 裝置最近一次進站時間
      DeviceLastUseAt: 0,
      // 最近一次綁定用戶的裝置
      DeviceLastLoginAt: 0
    };
  },
  computed: {

  },
  mounted () {

  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    Select2,
    SelectImage,
    draggable
  },
  watch: {
    currentTargetItem (_currentTargetItem) {
      if (!_currentTargetItem.id || _currentTargetItem.default) {
        return
      }

      console.log('=> 選定新聞項目:', _currentTargetItem)

      // 設置採用新聞資訊
      this.setNewsItem(_currentTargetItem.id)
    }
  },
  created () {
    this.currentBroadcastId = this.$route.params.broadcastId
    this.getBroadcastDetail()
    this.setBroadCastInit()
  },
  methods: {
    // 獲得推播項目內容
    getBroadcastDetail () {
      if (this.currentBroadcastId === 'create') {
        return
      }

      let vm = this
      this.$root.apis.getBroadcastItem(this.currentBroadcastId, function (_response) {
        let broadcast = _response.body.data
        vm.currentBroadcastTargetId = broadcast.target_id
        vm.BroadcastTargetType = broadcast.target_type
        vm.BroadcastTargetModel = broadcast.target_model
        vm.currentBroadcastUri = broadcast.target_uri
        vm.NeedSearchDeviceStatus = broadcast.device_type
        vm.currentSearchName = broadcast.device_search_name
        vm.DevicePlatform = broadcast.device_platform
        vm.DeviceLoginStatus = broadcast.device_login_status
        vm.DeviceLastUseAt = broadcast.device_last_use
        vm.DeviceLastLoginAt = broadcast.device_last_login
        vm.ScheduleType = broadcast.schedule_type
        vm.currentScheduleDate = vm.$root.common.getNowTime('date', broadcast.schedule_time + ':00')
        vm.currentScheduleTime = vm.$root.common.getNowTime('time', broadcast.schedule_time + ':00')
        vm.currentBroadcastTitle = broadcast.title
        vm.currentBroadcastBody = broadcast.body

        // 設置採用新聞資訊
        if (broadcast.target_type == 1 && broadcast.target_model == 'News') {
          vm.currentTargetItem = {
            default: true,
            id: broadcast.target.id,
            code: broadcast.target.code,
            name: '(' + broadcast.target.code + ')' + broadcast.target.title
          }
        }

        vm.broadcast = broadcast
      })
    },
    // 初始化推播項目建立
    setBroadCastInit () {
      if (this.currentBroadcastId !== 'create') {
        return
      }

      let targetDate = new Date()
      targetDate.setHours(targetDate.getHours() + 1)

      // 取得當下時間
      this.currentScheduleDate = this.$root.common.getNowTime('date', targetDate)
      this.currentScheduleTime = this.$root.common.getNowTime('time', targetDate)
    },
    // 搜尋新聞目標項目
    searchNewsList (_keyword) {
      let vm = this
      vm.NewsTargetOptions = []
      this.$root.apis.getFastNewsList({
        name: _keyword
      }, function (_response) {
        let items = _response.body.data.items
        items.forEach(function (_item) {
          vm.NewsTargetOptions.push(_item)
        })
      })
    },
    changeFilterTab (_status) {
      this.NeedSearchDeviceStatus = _status
    },
    changeDateTab (_status) {
      this.ScheduleType = _status
    },
    changeJumpTab (_status) {
      this.BroadcastTargetType = _status
    },
    changeJumpType (_type) {
      this.BroadcastTargetModel = _type
    },
    changeDevicePlatformTab (_platform) {
      this.DevicePlatform = _platform
    },
    changeLoginTab (_status) {
      this.DeviceLoginStatus = _status
    },
    changeLastTimeTab (_status) {
      this.DeviceLastUseAt = _status
    },
    changeNewUserTab (_status) {
      this.DeviceLastLoginAt = _status
    },
    // 設定新聞項目
    setNewsItem (_targetNewsId, _needSetTargetOption = false) {
      let vm = this
      this.$root.apis.getNewsDetail(_targetNewsId, function (_response) {
        let response = _response.body.data
        vm.currentBroadcastTitle = '中天新聞網'
        vm.currentBroadcastBody = response.title
        vm.currentBroadcastTargetId = response.id
      })
    },
    // 整理需要送出的資料
    processBroadcastData () {
      return {
        title: this.currentBroadcastTitle,
        body: this.currentBroadcastBody,
        target_type: this.BroadcastTargetType,
        target_model: this.BroadcastTargetModel,
        target_id: this.currentBroadcastTargetId,
        target_uri: this.currentBroadcastUri,
        device_type: this.NeedSearchDeviceStatus,
        device_search_name: this.currentSearchName,
        device_platform: this.DevicePlatform,
        device_login_status: this.DeviceLoginStatus,
        device_last_use: this.DeviceLastUseAt,
        device_last_login: this.DeviceLastLoginAt,
        schedule_type: this.ScheduleType,
        schedule_time: this.currentScheduleDate + ' ' + this.currentScheduleTime + ':00'
      }
    },
    // 搜尋推播對象
    searchMemberList (_text) {
      this.$root.apis.getMemberList()
    },
    // 請求建立推播項目
    createBroadcast () {
      let data = this.processBroadcastData()
      console.log('=> data:', data)

      let vm = this
      this.$root.apis.createBroadcast(data,
        function (_response) {
          let broadcastId = _response.body.data
          vm.currentBroadcastId = broadcastId
          vm.$root.common.showSingleNotify("推播項目已經建立成功！")
          vm.$router.push({ name: 'BroadcastEditor', params: { broadcastId: broadcastId } })
        },
        function (_error) {
          Array.isArray(_error.body.data?.body)
            ? vm.$root.common.showErrorNotify(_error.body.data.body.join('、'))
            : vm.$root.common.showErrorNotify(_error.body.data)
        }
      )
    },
    // 請求更新推播項目
    putBroadcast () {
      let data = this.processBroadcastData()
      console.log('=> data:', data)

      let vm = this
      this.$root.apis.putBroadcast(this.currentBroadcastId, data,
        function (_response) {
          vm.getBroadcastDetail()
          vm.$root.common.showSingleNotify("推播項目已經更新成功！")
        },
        function (_error) {
          Array.isArray(_error.body.data?.body)
            ? vm.$root.common.showErrorNotify(_error.body.data.body.join('、'))
            : vm.$root.common.showErrorNotify(_error.body.data)
        }
      )
    },
    // 請求取消推播項目
    cancelBroadcast () {
      let vm = this
      this.$root.common.confirmAction("取消推播", "您正在嘗試取消這個推播項目，確定要取消嗎？", "對，取消推播", "先不要", vm.processCancelBroadcastItem)
    },
    // 確認處理取消推播功能
    processCancelBroadcastItem () {
      let vm = this
      this.$root.apis.cancelBroadcastItem(this.currentBroadcastId,
        function (_response) {
          vm.getBroadcastDetail()
          vm.$root.common.showSingleNotify("推播項目已經取消成功！")
        },
        function (_error) {
          vm.$root.common.showErrorNotify(_error.body.data)
        }
      )
    }
  }
};
</script>

<style lang="sass" scoped>
.title-box
  display: flex
  justify-content: space-between
  align-items: center
  .addbtn
    display: flex
    justify-content: center
    align-items: center
    .ri-file-add-fill
      font-size: 1.4rem
      line-height: 1.5rem
.card-header
  background: #fff
.label-select
  border: none
  padding: 0
  height: auto
  line-height: 1
  font-weight: 600
.header-row
  display: flex
  align-items: center
  .header-title
    margin: 0
    flex: 1

.tab-group
  // background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
  button.off
    color: rgba(156,163,175,1)
    background: rgba(0,0,0,0)
    border-color: rgba(0,0,0,0)

.form-label-row
  display: flex
  gap: 14px

.add-btn
  width: 100%
  border: dashed 1px
.del-btn
  flex: 1
  display: flex
  align-items: center
  position: relative
  top: 3px
  .btn
    padding: 0rem .3rem
    border-radius: 50px
.img-view
  width: 400px
  height: 300px
  background: #e2e8f0
  border-radius: 5px
  margin-top: 5px
  border: 1px solid #ccc
  display: flex
  align-items: center
  justify-content: center
  position: relative
  i
    font-size: 30px

.gray-box
  background: #f1f5f9
  width: 100%
  padding: 10px
  display: flex
  justify-content: center

::v-deep .select2-container--default
  display: block
  width: 100%
  height: calc(1.5em + 0.9rem + 2px)
  // padding: 0.45rem 0.9rem
  font-size: 0.9rem
  font-weight: 400
  line-height: 1.5
  color: #6c757d
  background-color: #fff
  background-clip: padding-box
  border: 1px solid #ced4da
  border-radius: 0.2rem
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
  outline: none

::v-deep .select2-selection--single
  border: none
  display: flex
  align-items: center
  height: 100%
.form-label-row
  display: flex
  gap: 14px
::v-deep .table-btn
  text-decoration: underline
  color: #0073b8
  background: none
  border: none
  padding: 0
::v-deep .width-auto
  min-width: 7rem
  white-space: nowrap
  vertical-align: middle
  &.center
    text-align: center

.option-item
  display: flex
  gap: 10px
  width: 100%
  align-items: center
  ::v-deep .custom-control-label::after
    top: 45%
  ::v-deep .custom-control-label::before
    top: 45%
</style>
